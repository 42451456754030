import React, { useState, useEffect } from 'react';
import FadeIn from 'react-fade-in';
import { Link as LinkTo } from 'react-router-dom';
import styled from 'styled-components';
import qs from 'query-string';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';

import SSOIcon from '@material-ui/icons/Security';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import FormatQuoteIcon from '@material-ui/icons/FormatQuoteRounded';

import {
  PopUpAuth,
  getLocationFromIP,
  registerByEmailPass,
  fetchUrlForPromotion,
  adminImpersonateLogin,
  verifyCaptcha
} from 'utils/Firebase';
import { isIMP, isTest } from 'utils/env';
import GoogleIcon from 'static/images/google-icon.png';
import LogoImg from 'static/images/logo-small.png';
import { toast } from 'components/common/Toaster';
import Spinner from 'components/common/Spinner';
import useProfile from 'hooks/useProfile';

const ShowPasswordIconOn = styled(VisibilityIcon)`
  min-width: 48px;
  color: #999;
`;

const GeneralError = styled.div`
  height: 25px;
  color: #911;
  font-size: 0.8em;
  text-transform: uppercase;
`;

const ShowPasswordIconOff = styled(VisibilityOffIcon)`
  min-width: 48px;
  color: #999;
`;

const ResponsiveAccent = styled.div`
  @media (max-width: 1292px) {
    display: none;
  }
  @media (max-height: 880px) {
    display: none;
  }
`;

const Brands = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 20px 40px;
  opacity: 0.5;
  & img {
    margin: 0 20px 20px;
    max-width: 150px;
  }

  @media (min-width: 1001px) {
    @media (max-width: 1350px) {
      & img:first-child {
        display: none;
      }
    }

    @media (max-width: 1100px) {
      & img:nth-child(2) {
        display: none;
      }
    }
  }
  @media (max-height: 450px) {
    display: none;
  }
`;

const Root = styled.div``;

const Logo = styled.div`
  position: absolute;
  padding: 20px 0 0 20px;
  & img {
    width: 80px;
  }
  @media (max-width: 1000px) {
    & img {
      width: 49px;
    }
  }
`;

const Cont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  .left {
    max-width: 740px;
    padding: 20px 0;
  }
  & button {
    text-transform: initial;
    width: 100%;
    padding: 10px;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    & .left,
    .right {
      min-height: 100vh;
    }
  }
  position: relative;
`;

const Panel = styled.div`
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  &.right {
    background: rgb(250, 250, 250);
    display: flex;
    align-items: left;
    flex-direction: column;
    position: relative;
    @media (max-width: 780px) {
      background-size: 143px;
    }
  }
`;

const Form = styled.form`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: column;
  width: 311px;
  text-align: left;
  p {
    text-align: center;
  }
  input {
    text-align: left;
    display: block;
    padding: 6px 0 3px 0;
    width: 100%;
    padding: 10px;
    margin-bottom: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .MuiButton-containedPrimary {
    background-color: #4fae32;
    margin-top: 10px;
  }
`;

const InputCont = styled.div`
  width: 100%;
  position: relative;
  input {
    padding-right: 50px;
  }
  div {
    position: absolute;
    top: 6px;
    right: 0;
  }
`;

const H1 = styled.h1`
  margin-bottom: 26px;
  font-weight: 100;
  color: #444;
  div {
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: left;
  width: 100%;
`;

const OrCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0 15px;
  width: 100%;
`;

const OrLine = styled.div`
  border-bottom: 1px solid #ccc;
  flex: 1;
`;

const Or = styled.div`
  border-bottom: none;
  flex: 0;
  padding: 0 20px;
  font-size: 0.9em;
  white-space: nowrap;
  color: #888;
`;

const Quote = styled.div`
  margin: -40px 0;
  width: 523px;
  @media (max-width: 1280px) {
    width: 430px;
  }
  & p {
    margin: 5px 0;
  }
  & b {
    line-height: 1.6em;
    font-size: 1.4em;
  }
`;

const QuoteIcon = styled(FormatQuoteIcon)`
  font-size: 7em !important;
  color: #aba49e;
  margin-left: -55px;
  opacity: 0.2;
  transform: rotate(180deg);
`;

const Credit = styled.div`
  color: rgba(0, 0, 0, 0.5);
  @media (max-height: 540px) {
    display: none;
  }
`;

const TextContainer = styled.div`
  width: 70%;
  z-index: 1;
  margin-top: -40px;
`;

const A = styled.a`
  color: #2f85bb;
  :hover {
    text-decoration: underline;
  }
`;

const Link = styled(LinkTo)`
  color: #2f85bb;
  :hover {
    text-decoration: underline;
  }
`;

const Footer = styled.div`
  color: #777;
  padding: 10px;
  font-size: 0.9em;
  line-height: 1.5em;
  text-align: center;
`;

const Input = styled.input`
  ${({ error }) => error && 'border-color: #e25d5d;'}
`;

const Label = styled.label`
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 8px 0 6px 0;
  width: 100%;
  margin-left: 7px;
  color: #333;
  font-size: 0.9em;
  ${({ error }) => error && 'color: #e25d5d;'}
`;

const ErrorLabel = styled.span`
  min-height: 20px;
  font-size: 0.9em;
  &:empty {
    opacity: 0;
    transition: opacity 1s;
  }
  opacity: 1;
  color: #e25d5d;
  text-align: right;
  transition: opacity 1s;
  margin-right: 9px;
`;

// white statement above the quote
// const Statement = styled.div`
//   margin-top: -140px;
//   z-index: 99;
//   background-color: #FFF;
//   padding: 14px 15px;
//   margin-bottom: 42px;
//   border-radius: 5px;
//   line-height: 1.75em;
//   max-width: 520px;
//   @media (max-width: 1185px) {
//     margin-top: -50px;
//     max-width: 400px;
//     padding: 20px 19px;
//   }
// `

// <ErrorLabel direction='down'>{issues.all?.message}</ErrorLabel>
// watch()

const EnterprisePanel = ({ settings }) => {
  return (
    <Panel className="right" style={settings}>
      {settings.logo ? (
        <img
          src={settings.logo}
          alt="custom organization logo"
          style={settings.logoStyle || { maxHeight: '90%', maxWidth: '80%' }}
        />
      ) : (
        <h1>{settings.name}</h1>
      )}
    </Panel>
  );
};
const DefaultPanel = () => (
  <Panel className="right" role="contentinfo">
    <TextContainer>
      <Row>
        <H1
          style={{
            textAlign: 'left',
            zIndex: 1,
            width: '70%',
            minWidth: '370px',
            marginBottom: 0
          }}
        >
          Don't take our word for it...
        </H1>
      </Row>
      <Row>
        <QuoteIcon />
      </Row>
      <Quote>
        <p>
          <b>
            Konch transcription saved us several weeks of work and the related
            stress and carpal tunnel symptoms as well as several thousand
            dollars' worth of labor by students or professional
            transcriptionists.
          </b>
        </p>
        <br />
        <Credit>
          <p>Dr. Bayerlein.</p>
          <p>Professor, Texas A&M</p>
        </Credit>
      </Quote>
    </TextContainer>

    <div
      style={{
        position: 'absolute',
        left: 0,
        top: '4%',
        opacity: 0.3
      }}
    >
      <img
        style={{ width: '400px' }}
        src="https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/5ff247a24d31c90e23703630_accent_no_black.svg"
        alt="accent left"
      />
    </div>
    <ResponsiveAccent
      style={{
        position: 'absolute',
        right: 0,
        top: '50%',
        opacity: 0.5
      }}
    >
      <img
        src="https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/5ff248ebc8595f5875ba3e37_accent_right_no_black.svg"
        alt="accent right"
        style={{ width: '300px' }}
      />
    </ResponsiveAccent>
    <Brands>
      <img
        src="https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/5f8d12448490465fc6ee8e35_uni_nyu.png"
        width="100px"
        alt="Konch customer - New York University"
      />
      <img
        src="https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/5f8d1316d401615bf2e5ff3d_uni_tamu.png"
        alt="Konch customer - Texas A&M"
      />
      <img
        src="https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/5f8d10f94e5fcea31ab6b49e_lse.png"
        width="90px"
        alt="Konch customer - LSE London"
      />
      <img
        src="https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/5f8d10f834c9a0422eca20dd_uni_columbia.png"
        alt="Konch customer - Columbia"
      />
    </Brands>
  </Panel>
);

export default (props) => {
  const { history, settings = {} } = props;
  const { profile, isLoaded, uid } = useProfile();
  const [processing, setProcessing] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(!isTest() ? '' : 'Test');
  const [processingSSO, setProcessingSSO] = useState(false);
  const [generalError, setGeneralError] = useState('');
  const { key, product, redirect } = qs.parse(props.location.search.substr(1));

  const { register, handleSubmit, errors, setError } = useForm({
    mode: 'onBlur',
    shouldFocusError: true
  });
  const [showPassword, setShowpassword] = useState(false);

  const nextPage =
    profile?.settings?.homePage === 'corrector'
      ? '/corrector'
      : '/dashboard' + (product ? '?product=' + product : '');
  const page =
    redirect && redirect.indexOf('logout') === -1 ? redirect : nextPage;

  const onSubmit = async ({ email, password, name }) => {
    if (processing) return;
    if (
      !window.localStorage.getItem('open-registration') &&
      email.includes('+') &&
      !email
        .split('@')
        .slice(1)
        .join('')
        .match(/^(konch\.ai|ops\.getwingapp\.com|gitstart.com)$/i)
    ) {
      return setError('email', {
        type: 'manual',
        message: '"+" is not allowed'
      });
    }
    setProcessing(true);
    if (!isTest()) {
      const isCaptchaValid = await verifyCaptcha(captchaValue);
      if (!isCaptchaValid) {
        setGeneralError(<span>Captcha could not be verified!</span>);
        setProcessing(false);
        setCaptchaValue('');
        return;
      }
    }
    const { error, message } = await registerByEmailPass({
      email,
      password,
      name
    });
    if (!error) {
      history.push(page);
    } else {
      if (message.indexOf('The email address is already in use') >= 0) {
        setError('email', { type: 'manual', message: 'Email already taken.' });
        setGeneralError(
          <span>
            Login instead? <Link to="/signin">Click here.</Link>
          </span>
        );
      } else {
        console.log(error, message);
        toast.error(message, { autoClose: 5000 });
        setGeneralError(
          <span>
            No account? <Link to="/register">Register here.</Link>
          </span>
        );
      }
    }
    setProcessing(false);
  };

  const onSSOAuth = async (provider) => {
    setProcessingSSO(true);
    try {
      const popupResult = await PopUpAuth(provider, settings.organization);
      history.push(page);
      console.log('popupResult', JSON.stringify(popupResult, null, 3));
    } catch (error) {
      console.log('popupResult', error);
      toast.error(error.message, { autoClose: 5000 });
    }
    setProcessingSSO(false);
  };

  const forceLogin = async (key) => {
    const allowed = await adminImpersonateLogin(key);
    console.log({ allowed });
    if (allowed) history.push(nextPage);
  };

  const captureCountryCode = async () => {
    const ipInfo = await getLocationFromIP();
    const countryCode = (ipInfo.data || {}).country_code;
    window.localStorage.setItem('country_code', countryCode);
  };

  useEffect(() => {
    captureCountryCode();
    document.title = 'Register Free with Konch';
    fetchUrlForPromotion();
    console.log({ isIMP, key });
    if (isIMP && key) forceLogin(key, history);
    return () => {
      document.title = 'Konch';
    };
  }, []);

  useEffect(() => {
    const skipAutoLogin = localStorage.getItem('konch-skip-auto-login');
    console.log({ skipAutoLogin, profile, redirect, nextPage });
    if (profile.isLoaded && !profile.isEmpty) {
      console.log('forward to', page);
      !skipAutoLogin && history.push(page);
    }
  }, [profile.isLoaded, isLoaded, uid, profile.isEmpty]);

  const handleCaptchaChange = async (value) => {
    if (value) {
      setCaptchaValue(value);
    }
  };

  return (
    <Root>
      {!settings.active && (
        <Logo role="banner">
          <img src={LogoImg} alt="logo" />
        </Logo>
      )}
      <Cont>
        <Panel className="left" role="main">
          <Form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <H1>
              <b>Register</b> an account<div>Free and easy signup</div>
            </H1>

            {/* if not enterprise, or is enterprise and allows google*/}
            <div style={{ display: 'flex', width: '100%' }}>
              {(!settings.active || settings.allowGoogle) && (
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  style={{
                    backgroundColor: '#FFF',
                    color: '#333',
                    border: '1px solid #ccc'
                  }}
                  startIcon={
                    processingSSO ? (
                      <Spinner small />
                    ) : (
                      <img
                        src={GoogleIcon}
                        width="20px"
                        height="20px"
                        alt="google icon"
                      />
                    )
                  }
                  onClick={() => onSSOAuth('google')}
                >
                  Register using Google
                </Button>
              )}
              {settings.provider && (
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  style={{
                    backgroundColor: '#FFF',
                    color: '#333',
                    border: '1px solid #ccc'
                  }}
                  startIcon={
                    processingSSO ? (
                      <Spinner small />
                    ) : (
                      <SSOIcon
                        style={{ width: '20px', height: '20px' }}
                        alt="sso icon"
                      />
                    )
                  }
                  onClick={() => onSSOAuth(settings.provider)}
                >
                  Register using SSO
                </Button>
              )}
            </div>

            {(!settings.active || settings.allowEmail) && (
              <div style={{ width: '100%' }}>
                {(!settings.active ||
                  settings.provider ||
                  settings.allowGoogle) && (
                  <OrCont>
                    <OrLine />
                    <Or>or sign in via email</Or>
                    <OrLine />
                  </OrCont>
                )}
                <GeneralError>
                  {generalError && <FadeIn>{generalError}</FadeIn>}
                </GeneralError>
                <Label htmlFor="name" error={errors.name}>
                  Name{' '}
                  <ErrorLabel>
                    {errors.name && ` ${errors.name.message}`}
                  </ErrorLabel>
                </Label>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  error={errors.name}
                  className={errors.name ? 'small-shake' : ''}
                  autoComplete="off"
                  ref={register({
                    required: 'Please enter a name',
                    pattern: {
                      value: /^([a-zA-Z\\.]{3,}(|(\s)[a-zA-Z]+).*)+$/i,
                      message: 'Please use a real name'
                    }
                  })}
                />

                <Label htmlFor="email" error={errors.email}>
                  Email{' '}
                  <ErrorLabel>
                    {errors.email && ` ${errors.email.message}`}
                  </ErrorLabel>
                </Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  error={errors.email}
                  className={errors.email ? 'small-shake' : ''}
                  autoComplete="off"
                  ref={register({
                    required: 'Please enter a valid email',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address'
                    }
                  })}
                />

                <Label htmlFor="password" error={errors.password}>
                  Password{' '}
                  <ErrorLabel>
                    {errors.password && ` ${errors.password.message}`}
                  </ErrorLabel>
                </Label>
                <InputCont>
                  <Input
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    error={errors.password}
                    className={errors.password ? 'small-shake' : ''}
                    autoComplete="off"
                    ref={register({
                      required: 'Please enter a password.',
                      minLength: {
                        value: 6,
                        message: 'Must be at least 6 characters'
                      }
                    })}
                  />
                  <div>
                    <span
                      onClick={() => setShowpassword(!showPassword)}
                      tabIndex={0}
                      role="button"
                      styling="text"
                      aria-labelledby="Toggle Show Password"
                      aria-label="Toggle Show Password"
                      title="Toggle Show Password"
                      onKeyDown={(evt) =>
                        evt.keyCode === 13 && setShowpassword(!showPassword)
                      }
                      style={{ outline: 'none' }}
                    >
                      {showPassword ? (
                        <ShowPasswordIconOn />
                      ) : (
                        <ShowPasswordIconOff />
                      )}
                    </span>
                  </div>
                </InputCont>
                {!isTest() && (
                  <ReCAPTCHA
                    onChange={handleCaptchaChange}
                    sitekey={process.env.REACT_APP_SITE_KEY}
                  />
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!captchaValue}
                  style={{
                    backgroundColor: settings.buttonColor || '#2091d0',
                    fontSize: '1em',
                    height: '48px'
                  }}
                  disableElevation
                  onClick={(evt) => {
                    console.log(evt);
                  }}
                >
                  {processing ? (
                    <Spinner small color="#FFF" />
                  ) : (
                    'Create your account'
                  )}
                </Button>
                <Footer>
                  Already have an account? <Link to="/signin">Login</Link>
                  <p>
                    By registering, I agree to Konch's <br />
                    <A href="https://www.konch.ai/terms-of-service">Terms</A>
                    &nbsp;and&nbsp;
                    <A href="https://www.konch.ai/privacy-policy">
                      Privacy Policy
                    </A>
                  </p>
                </Footer>
              </div>
            )}
          </Form>
        </Panel>
        {settings.active ? <EnterprisePanel {...props} /> : <DefaultPanel />}
      </Cont>
    </Root>
  );
};
