import React, { useState, useEffect } from 'react';
import ErrorBoundary from 'utils/ErrorBoundaryV2';
import { sendPasswordResetEmail } from 'utils/Firebase';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import KonchButton from 'components/common/Button';
import { useForm } from 'react-hook-form';
import LogoImg from 'static/images/konch-logo-with-text.svg';
import Spinner from 'components/common/Spinner';

const RegisterButton = styled(KonchButton)`
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
`;

const Root = styled.div`
  height: 100vh;
  background: none;
`;

const Logo = styled.div`
  position: absolute;
  padding: 20px 0 0 20px;
  & img {
    width: 160px;
  }
  @media (max-width: 1000px) {
    & img {
      width: 120px;
    }
  }
`;

const Cont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 550px;
  & button {
    text-transform: initial;
    width: 100%;
    padding: 10px;
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    & .left {
      max-height: 600px;
    }
    & .right {
      justify-content: end;
      max-height: 900px;
    }
  }
`;

const Panel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  &.right {
    background: #fff1e5;
    display: flex;
    align-items: left;
    flex-direction: column;
  }
`;

const PanelContent = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  padding: 30px 100px 0;
  width: 100%;
`;

const H1 = styled.h1`
  margin-bottom: 35px;
  font-weight: 100;
  color: #444;
  @media (max-width: 700px) {
    font-size: 1.4em;
    padding: 0;
    margin: 19px;
  }
`;

const Form = styled.form`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: column;
  width: 304px;
  text-align: left;
  p {
    text-align: center;
  }
  input {
    text-align: left;
    display: block;
    padding: 6px 0 3px 0;
    width: 100%;
    padding: 10px;
    margin-bottom: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .MuiButton-containedPrimary {
    background-color: #4fae32;
    margin-top: 10px;
  }
`;

const A = styled.a`
  color: #2f85bb;
  :hover {
    text-decoration: underline;
  }
`;

const Footer = styled.p`
  color: #777;
  padding: 10px;
  font-size: 0.9em;
`;

const Input = styled.input`
  ${({ error }) => error && 'border-color: #e25d5d;'}
`;

const Label = styled.label`
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 8px 0 6px 0;
  width: 100%;
  margin-left: 7px;
  color: #333;
  font-size: 0.9em;
  ${({ error }) => error && 'color: #e25d5d;'}
`;

const ErrorLabel = styled.span`
  min-height: 20px;
  font-size: 0.9em;
  &:empty {
    opacity: 0;
    transition: opacity 1s;
  }
  opacity: 1;
  color: #e25d5d;
  text-align: right;
  transition: opacity 1s;
  margin-right: 9px;
`;

const EnterprisePanel = ({ settings }) => (
  <Panel className="right" style={settings}>
    <img
      src={settings.logo}
      alt="custom organization logo"
      style={{ maxHeight: '60%', maxWidth: '60%' }}
    />
  </Panel>
);

const DefaultPanel = () => (
  <Panel className="right" role="contentinfo">
    <PanelContent>
      <H1>Let's get you back in!</H1>
    </PanelContent>
    <div style={{ height: '100%', width: '550px', overflow: 'hidden' }}>
      <img
        src="https://uploads-ssl.webflow.com/5f8a27fa913a100a39ea46f9/6022008f5b8d8759df49e651_security-art.svg"
        alt="classroom setting"
      />
    </div>
  </Panel>
);

// <ErrorLabel direction='down'>{issues.all?.message}</ErrorLabel>
// watch()
export default (props) => {
  const { history, settings = {} } = props;
  const [processing, setProcessing] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    shouldFocusError: true
  });

  const onSubmit = async ({ email, password }) => {
    if (processing || isSent) return;
    setProcessing(true);
    const { errors } = await sendPasswordResetEmail({ email });
    if (!errors) setIsSent(true);
    setProcessing(false);
  };

  useEffect(() => {
    document.title = 'Reset password';
    return () => {
      document.title = 'Konch';
    };
  }, []);

  return (
    <ErrorBoundary>
      <Root>
        {!settings.active && (
          <Logo role="banner">
            <img src={LogoImg} alt="logo" />
          </Logo>
        )}
        <Cont>
          <Panel className="left" role="main">
            <Form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <H1>Reset your password</H1>
              <Label htmlFor="email" error={errors.email}>
                Email{' '}
                <ErrorLabel>
                  {errors.email && ` ${errors.email.message}`}
                </ErrorLabel>
              </Label>
              <Input
                id="email"
                name="email"
                type="email"
                error={errors.email}
                className={errors.email ? 'small-shake' : ''}
                autoComplete="off"
                ref={register({
                  required: 'Please enter a valid email',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address'
                  }
                })}
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{
                  backgroundColor:
                    settings.buttonColor || (isSent ? '#f5f5f5' : '#21b573'),
                  fontSize: '1em',
                  height: '48px'
                }}
                disableElevation
                disabled={isSent}
                onClick={(evt) => {
                  console.log(evt);
                }}
              >
                {processing ? (
                  <Spinner small color="#FFF" />
                ) : isSent ? (
                  <span style={{ display: 'flex' }}>
                    Sent&nbsp;&nbsp;
                    <DoneOutlineIcon style={{ width: '16px' }} />
                  </span>
                ) : (
                  'Send reset email'
                )}
              </Button>
              <Footer>
                <p>
                  Don't have an account, yet?{' '}
                  <A href="/register">Create one here</A>.
                </p>
                <p>
                  Remember your password? <A href="/signin">Login here</A>.
                </p>
              </Footer>
            </Form>
          </Panel>
          {settings.active ? <EnterprisePanel {...props} /> : <DefaultPanel />}
        </Cont>
        {!settings.active && (
          <RegisterButton
            raised
            primary
            onClick={() => history.push('/register')}
          >
            Register for Free
          </RegisterButton>
        )}
      </Root>
    </ErrorBoundary>
  );
};
